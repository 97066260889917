
import { mean } from "d3-array";

import ema from "./ema";
import sma from "./sma";
import atr from "./atr";
import hma from "./hma";
import { isDefined, last, slidingWindow, zipper, path, isNotDefined } from "../utils";
import { emv as defaultOptions } from "./defaultOptionsForComputation";

export default function() {
// ALGORITHM NAME : Easy of Movement(EMV)
	let options = defaultOptions;

	function calculator(data) {
		const { windowSize, movingAverageType, windowSizeSignal, movingAverageTypeSignal, sourcePath } = options;

		const source = sourcePath === "high/low/volume"
			? d => { return {  high: d.high, low: d.low, volume: d.volume  }; }
			: d => { return {  high: d.high, low: d.low, volume: d.volume  }; };

		let high = d => source(d).high,
			low = d => source(d).low,
			volume = d => source(d).volume;
		const maAlgorithm = movingAverageType === "sma"
			? sma().options({ windowSize: windowSize, sourcePath: "close" })
			: slidingWindow().windowSize(windowSize);

		const signalAlgorithm = movingAverageTypeSignal === "sma"
			? sma().options({ windowSize: windowSizeSignal, sourcePath: undefined })
			: slidingWindow().windowSize(windowSizeSignal);

		const trueRangeAlgorithm = slidingWindow()
			.windowSize(2)
			.source(source)
			.accumulator(values => {
				const prev = values[0];
				const d = values[1];
				return d.high;// ((((d.high+d.low)/2-(prev.high+prev.low)/2)/(1/(d.high-d.low))));
			});

		const trueRangeAlgorithm1 = slidingWindow()
			.windowSize(2)
			.source(source)
			.accumulator(values => {
				const prev = values[0];
				const d = values[1];
				return (d.high - prev.high) * 20;   // (((d.high+d.low)/2-(prev.high+prev.low)/2)/(d.volume/(d.high-d.low)));
			});
		//	var newData1 = trueRangeAlgorithm(data);
		const QstickCalculator = zipper()
			.combine((trueRangeAlgorithm, trueRangeAlgorithm1) => (isDefined(trueRangeAlgorithm) && isDefined(trueRangeAlgorithm1)) ? trueRangeAlgorithm : undefined);

		const QstickArray = QstickCalculator(trueRangeAlgorithm(data), trueRangeAlgorithm1(data));
		// console.log(QstickArray);
		const undefinedArray = new Array(windowSizeSignal);
		const RezArray = undefinedArray.concat(signalAlgorithm(QstickArray.slice(windowSizeSignal)));
		//	console.log(RezArray);

		const MAArray = maAlgorithm(data);
		// console.log(MAArray);
		// var QstickCalculator = zipper()
		//	.combine(trueRangeAlgorithm => (isDefined(trueRangeAlgorithm)) ? trueRangeAlgorithm : undefined);
		//	var QstickArray = QstickCalculator(trueRangeAlgorithm(data));
		//	console.log(QstickArray);
		//	var undefinedArray = new Array(windowSize);
		//	var RezMas = undefinedArray.concat(signalAlgorithm(QstickArray.slice(windowSize)));
		//	console.log(RezMas);

		const EMVAlgorithm = slidingWindow()
		//	.windowSize(windowSize)
			.sourcePath(undefined)
			.accumulator((values) => {
				const sig = last(values).sig;
				const mean = last(values).mean;
				return {
					signalline: sig,
					line: mean
				};
			});

		const zip = zipper()
			.combine((datum, sig, mean) => ({ datum, sig, mean }));
		// var tuples = zip(data, signalAlgorithm(data), maAlgorithm(data));
		const tuples = zip(data, RezArray, MAArray);

		return EMVAlgorithm(tuples);
	}

	calculator.undefinedLength = function() {
		const { windowSize } = options;

		return windowSize - 1;
	};
	calculator.options = function(x) {
		if (!arguments.length) {
			return options;
		}
		options = { ...defaultOptions, ...x };
		return calculator;
	};

	return calculator;
}
