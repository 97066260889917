

import { slidingWindow, isNotDefined, path, isDefined, zipper, last } from "../utils";
import { bw_mfi as defaultOptions } from "./defaultOptionsForComputation";

export default function() {

	let options = defaultOptions;

	function calculator(data) {
		const { windowSize, sourcePath, multiplier } = options;
	     const ml = multiplier;
		const source = sourcePath === "high/low/volume"
			? d => { return { high: d.high, low: d.low, volume: d.volume }; }
			: d => { return { high: d.high, low: d.low, volume: d.volume }; };
		let previous;
		let skip = 0;
		const i = 0;
		let rez = 0;

		return data.map(function(d, i) {
			const h = d.high;
			const l = d.low;
			const v = d.volume;

			if ( isNotDefined(h)) {
				skip++;
				return undefined;
			} else {
				rez = ml * (h - l) / l;
				return rez;
			}
		});
	}
	calculator.undefinedLength = function() {
		const { windowSize } = options;
		return windowSize - 1;
	};
	calculator.options = function(x) {
		if (!arguments.length) {
			return options;
		}
		options = { ...defaultOptions, ...x };
		return calculator;
	};

	return calculator;
}
